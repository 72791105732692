<template>
  <!-- ***** Preloader Start ***** -->
  <div id="js-preloader" class="js-preloader">
    <div class="preloader-inner">
      <span class="dot"></span>
      <div class="dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  <!-- ***** Preloader End ***** -->

  <!-- ***** Header Area Start ***** -->
  <header class="header-area header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">
            <!-- ***** Logo Start ***** -->
            <a href="/" class="logo">
              <img
                src="assets/images/Africa-tour-rwanda-200-x-67-px.png"
                alt=""
              />
            </a>
            <!-- ***** Logo End ***** -->
            <!-- ***** Menu Start ***** -->
            <ul class="nav">
              <li><a href="/" class="active">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/deals">Deals</a></li>
              <li><a href="/itineraries">itineraries</a></li>
              <li><a href="/booking">Book Yours</a></li>
            </ul>
            <a class="menu-trigger">
              <span>Menu</span>
            </a>
            <!-- ***** Menu End ***** -->
          </nav>
        </div>
      </div>
    </div>
  </header>
  <!-- ***** Header Area End ***** -->

  <!-- ***** Main Banner Area Start ***** -->
  <section id="section-1">
    <div class="content-slider">
      <input
        type="radio"
        id="banner1"
        class="sec-1-input"
        name="banner"
        checked
      />
      <input type="radio" id="banner2" class="sec-1-input" name="banner" />
      <input type="radio" id="banner3" class="sec-1-input" name="banner" />
      <input type="radio" id="banner4" class="sec-1-input" name="banner" />
      <div class="slider">
        <div id="top-banner-1" class="banner">
          <div class="banner-inner-wrapper header-text">
            <div class="main-caption">
              <h2 style="color: black">
                Take a Glimpse Into The Beautiful Country Of:
              </h2>
              <h1 style="color: black">Rwanda</h1>
              <div class="border-button"><a href="/rwanda">Go There</a></div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="more-info">
                    <div class="row">
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-user"></i>
                        <h4><span>Population:</span><br />14.48 M</h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-globe"></i>
                        <h4>
                          <span>Territory:</span><br />275.400 KM<em>2</em>
                        </h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-home"></i>
                        <h4><span>AVG Price:</span><br />$946.000</h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <div class="main-button">
                          <a href="/rwanda">Explore More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="top-banner-2" class="banner">
          <div class="banner-inner-wrapper header-text">
            <div class="main-caption">
              <!-- <h2 style="color: black;">Take a Glimpse Into The Beautiful Place of:</h2>
              <h1 style="color: black;">Akagera Nationa Park</h1> -->
              <div class="border-button"><a href="/akagera">Go There</a></div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="more-info">
                    <div class="row">
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-user"></i>
                        <h4><span>Destination:</span><br />Eastern Province</h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-globe"></i>
                        <h4>
                          <span>Visit:</span><br />Akagera National Park<em
                            >2</em
                          >
                        </h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-home"></i>
                        <h4><span>AVG Price:</span><br />$1.100.200</h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <div class="main-button">
                          <a href="/akagera">Explore More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="top-banner-3" class="banner">
          <div class="banner-inner-wrapper header-text">
            <!-- <div class="main-caption">
              <h2>Take a Glimpse Into The Beautiful Canopy Of:</h2>
              <h1>Nyungwe National Park</h1>
              <div class="border-button">
                <a href="/nyungwe-national-park">Go There</a>
              </div>
            </div> -->
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="more-info">
                    <div class="row">
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-user"></i>
                        <h4>
                          <span>Destination:</span><br />Southern Province
                        </h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-globe"></i>
                        <h4>
                          <span>Visit:</span><br />Nyungwe National Park<em
                          ></em>
                        </h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-home"></i>
                        <h4><span>AVG Price:</span><br />$425.600</h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <div class="main-button">
                          <a href="/nyungwe-national-park">Explore More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="top-banner-4" class="banner">
          <div class="banner-inner-wrapper header-text">
            <!-- <div class="main-caption">
              <h2>
                This unforgettable experience combines breathtaking scenery with
                the chance to observe these gentle giants Of:
              </h2>
              <h1>Volcanoes Mountain Gorilla</h1>
              <div class="border-button"><a href="/mountain-gorilla">Go There</a></div>
            </div> -->
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="more-info">
                    <div class="row">
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-user"></i>
                        <h4>
                          <span>Destination:</span><br />Northern Province
                        </h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-globe"></i>
                        <h4>
                          <span>Visit:</span><br />Volcanoes Mountain Gorilla<em
                          ></em>
                        </h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <i class="fa fa-home"></i>
                        <h4><span>AVG Price:</span><br />$165.450</h4>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6">
                        <div class="main-button">
                          <a href="/mountain-gorilla">Explore More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav>
        <div class="controls">
          <label for="banner1"
            ><span class="progressbar"
              ><span class="progressbar-fill"></span></span
            ><span class="text">1</span></label
          >
          <label for="banner2"
            ><span class="progressbar"
              ><span class="progressbar-fill"></span></span
            ><span class="text">2</span></label
          >
          <label for="banner3"
            ><span class="progressbar"
              ><span class="progressbar-fill"></span></span
            ><span class="text">3</span></label
          >
          <label for="banner4"
            ><span class="progressbar"
              ><span class="progressbar-fill"></span></span
            ><span class="text">4</span></label
          >
        </div>
      </nav>
    </div>
  </section>
  <!-- ***** Main Banner Area End ***** -->

  <div class="visit-country">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="section-heading">
            <h2 style="color: white">Visit One Of Our Destination Now</h2>
            <p>
              At Africa Tour Rwanda, we specialize in crafting unforgettable
              journeys through the heart of Africa. Rwanda offers a breathtaking
              most of natural beauty, rich culture, and unique wildlife
              experiences..
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="items">
            <div class="row">
              <div class="col-lg-12">
                <div class="item">
                  <div class="row">
                    <div class="col-lg-4 col-sm-5">
                      <div class="image">
                        <img src="assets/images/akagera.png" alt="" />
                      </div>
                    </div>
                    <div class="col-lg-8 col-sm-7">
                      <div class="right-content">
                        <h4 style="color: white">Eastern Province</h4>
                        <span style="color: white;">Akagera National Park</span>
                        <div class="main-button">
                          <a href="/akagera-national-park">Explore More</a>
                        </div>
                        <p>
                          Akagera National Park in Rwanda is a popular
                          destination for wildlife enthusiasts, offering a
                          varietyof activities such as wildlife safaris, bird
                          watching, guided night drives, game viewing towers,
                          scenic drives, boat trips, conservation programs, pick
                          nick and camping, and cultural experiences..
                        </p>
                        <ul class="info">
                          <li><i class="fa fa-user"></i> 8.66 Mil People</li>
                          <li><i class="fa fa-globe"></i> 41.290 km2</li>
                          <li><i class="fa fa-home"></i> $1.100.200</li>
                        </ul>
                        <div class="text-button">
                          <a href="/about"
                            >Need Directions ? <i class="fa fa-arrow-right"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="item">
                  <div class="row">
                    <div class="col-lg-4 col-sm-5">
                      <div class="image">
                        <img
                          src="assets/images/gorilla-trek-rwanda-pnv.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-8 col-sm-7">
                      <div class="right-content">
                        <h4 style="color: white">Northern Province</h4>
                        <span style="color: white;">Volcanoes Mountain Gorilla</span>
                        <div class="main-button">
                          <a href="/mountain-gorilla">Explore More</a>
                        </div>
                        <p>
                          Volcanoes National Park in Rwanda is a popular
                          destination for tourists, offering stunning landscapes
                          and the chance to see mountain gorillas in their
                          natural habitat. Activities include gorilla trekking,
                          golden monkey tracking, hiking and volcano climbing,
                          visiting Dian Fossey's grave, cultural experiences,
                          bird watching, and photography.
                        </p>
                        <ul class="info">
                          <li><i class="fa fa-user"></i> 44.48 Mil People</li>
                          <li><i class="fa fa-globe"></i> 275.400 km2</li>
                          <li><i class="fa fa-home"></i> $946.000</li>
                        </ul>
                        <div class="text-button">
                          <a href="/about"
                            >Need Directions ? <i class="fa fa-arrow-right"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="item last-item">
                  <div class="row">
                    <div class="col-lg-4 col-sm-5">
                      <div class="image">
                        <img src="assets/images/nyungwe-3.jpg" alt="" />
                      </div>
                    </div>
                    <div class="col-lg-8 col-sm-7">
                      <div class="right-content">
                        <h4 style="color: white;">Eastern Province</h4>
                        <span style="color: white;">Nyungwe National Park</span>
                        <div class="main-button">
                          <a href="/nyungwe-national-park">Explore More</a>
                        </div>
                        <p>
                          Nyungwe Forest National Park in Rwanda offers a range
                          of attractions including chimpanzee tracking, bird
                          watching, guided nature walks, scenic waterfalls,
                          colobus monkey tracking, cultural encounters with
                          local communities, and photography opportunities.
                        </p>
                        <ul class="info">
                          <li><i class="fa fa-user"></i> 67.41 Mil People</li>
                          <li><i class="fa fa-globe"></i> 551.500 km2</li>
                          <li><i class="fa fa-home"></i> $425.600</li>
                        </ul>
                        <div class="text-button">
                          <a href="/about"
                            >Need Directions ? <i class="fa fa-arrow-right"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <ul class="page-numbers">
                  <li>
                    <a href="#"><i class="fa fa-arrow-left"></i></a>
                  </li>
                  <li><a href="#">1</a></li>
                  <li class="active"><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li>
                    <a href="#"><i class="fa fa-arrow-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="side-bar-map">
            <div class="row">
              <div class="col-lg-12">
                <div id="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12469.776493332698!2d-80.14036379941481!3d25.907788681148624!2m3!1f357.26927939317244!2f20.870722720054623!3f0!3m2!1i1024!2i768!4f35!3m3!1m2!1s0x88d9add4b4ac788f%3A0xe77469d09480fcdb!2sSunny%20Isles%20Beach!5e1!3m2!1sen!2sth!4v1642869952544!5m2!1sen!2sth"
                    width="100%"
                    height="550px"
                    frameborder="0"
                    style="border: 0; border-radius: 23px"
                    allowfullscreen=""
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="call-to-action">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h3 style="color: white;">Are You Looking To Travel in Rwanda ?</h3>
          <h4>Make A Reservation By Clicking The Button</h4>
        </div>
        <div class="col-lg-4">
          <div class="border-button">
            <a href="/booking">Book Yours Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p>
            Copyright © 2024 <a href="#">Africa Tour Rwanda</a>. All rights
            reserved. <br />Design By:
            <a
              href="#"
              target="_blank"
              title="Heritier Ganza Tamba"
              >Heritier Ganza Tamba</a
            >
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
